import React from 'react'
import logo from './logo.png'
import {BsArrowRight} from 'react-icons/bs'


export default function Header() {
  const handleClick = () => {
    window.location.replace("/generationPage");
  };
  return (
    <div>
      <div className='flex justify-between border border-b-black/10 py-5' >
        <div className='font-bold flex flex-row items-center text-xl space-x-4 ml-5'>
          <img src={logo} className='w-12' />
          <h1 className='text-xl'>
          VINOD DULAL YOGA INSTITUTE
          </h1>
        </div>
        <div>
          <button className='mr-5 flex flex-row items-center py-2 px-5 text-lg rounded-lg bg-myOrange/80 hover:bg-myOrange border border-black/10 transition-all ease-linear' onClick={handleClick}>
            Try it
            <BsArrowRight className='ml-2'/>
          </button>
        </div>
      </div>
    </div>
  )
}
