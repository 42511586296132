import React from 'react';
import Header from '../Component/Header';
import MyImage from './preview.png';

export default function LandingPage() {
  return (
    <div className="bg-myPink/50 min-h-screen">
      <Header />
      <div className="flex flex-col h-full items-center">
        <div className="flex justify-center mt-20">
          <div className="font-bold text-2xl md:text-3xl lg:text-5xl">
            Create Yoga routine activities
          </div>
        </div>
        <div className="flex justify-center mb-5">
          <div className="font-bold text-2xl md:text-3xl lg:text-5xl mt-3">using AI in seconds</div>
        </div>
        <div className="flex justify-center mt-24">
          <div className="flex justify-center max-w-full sm:w-full">
            <img
              src={MyImage}
              alt="Description of the image"
              className="object-contain h-auto w-2/3"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
