import logo from './logo.svg';
import './App.css';
import GenerationPage from './pages/generationPage';
import GeneratedSequence from './pages/generatedSequence';
import LandingPage from './pages/LandingPage';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'


function App() {

  
  return (
    <>
      <Router>
        <Switch>
          <Route path="/" exact component={LandingPage} />
          <Route path="/generationPage" exact component={GenerationPage} />
          <Route path="/generatedSequence" exact component={GeneratedSequence} />
        </Switch>
      </Router> 
    </>
  );
}

export default App;
